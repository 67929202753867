import React, { useEffect, useState } from "react";
import style from "./Dashboard.module.scss";
import { ResponsiveBar } from "@nivo/bar";
import Footer from "../Footer/Footer";
import TopMenu from "../TopMenu/TopMenu";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faTrophy,
  faStar,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
const Dashboard = () => {
  let headers = [
    { label: "Restarunt Name", key: "place_name" },
    { label: "Category Name", key: "category_name" },
    { label: "Address", key: "address" },
    { label: "County", key: "county_name" },
    { label: "Unique key (AC ID)", key: "contact_id" },
    { label: "Eircode", key: "eircode" },
    { label: "Region Name", key: "region_name" },
    { label: "Best Chef Name", key: "best_chef_name" },
    { label: "Best Restaurant Manager Name", key: "best_restaurant_manager_name" },
    { label: "Best Hero Name", key: "best_hero_name" },
    { label: "Best Hotel & Guesthouse Restaurant", key: "best_hotel_guesthouse_restaurant_name" },
    { label: "Best Hero Description", key: "best_hero_description" }
  ];
  let [csvData, setCsvData] = useState([]);
  let [data, setData] = useState([]);
  let [dataTest, setDataTest] = useState([]);
  let [totalVotes, setTotalVotes] = useState(0);
  let [desktopVotes, setDesktopVotes] = useState(0);
  let [mobileVotes, setMobileVotes] = useState(0);
  let [averageDailyVotes, setAverageDailyVotes] = useState(15);
  let [averageWeeklyVotes, setAverageWeeklyVotes] = useState(3);
  const [counties, setCounties] = useState([]);
  const [categories, setCategories] = useState([]);
  const [nationalCateg, setNationalCateg] = useState([]);
  let [dailyVotesChangePercentage, setDailyVotesChangeProcentage] = useState(0);
  let [weeklyVotesChangePercentage, setWeeklyVotesChangePercentage] =
    useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [totalVoters, setTotalVoters] = useState(0);
  const [selectedCounty, setSelectedCounty] = useState("");
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });
  const isMobile = width <= 768;
  const [nominatedCategories, setNominatedCategory] = useState([]);
  useEffect(() => {
    const testURLWinners = process.env.REACT_APP_DASHBOARD_URL +`?view=winners&county=${selectedCounty}`;
    categories.map((elem) => {
      elem.winners = [];
    });
    const testURLExpoertData = process.env.REACT_APP_DASHBOARD_URL +`?view=exportVote`;
    axios.get(testURLExpoertData).then((exportData) => {
      if(exportData){
        setCsvData(exportData.data);
      }
    });
    if (selectedCounty !== "")
      axios.get(testURLWinners).then((res) => {
        let arr = [];
        res.data.map((elem) => {
          let container = {
            category: elem.category_name,
            places: [elem.place_name],
          };
          if (arr.filter((e) => e.category === elem.category_name).length > 0) {
            arr.map((el) => {
              if (el.category === elem.category_name) {
                el.places.push(elem.place_name);
              }
            });
          } else {
            arr.push(container);
          }
          if (
            categories.filter((e) => e.categoryName === elem.category_name)
              .length > 0
          ) {
            categories.map((el) => {
              if (el.categoryName === elem.category_name) {
                el.winners.push(elem.place_name);
              }
            });
          }
        });
        setNominatedCategory(arr);
      });
  }, [selectedCounty]);
  let csvDataChart = [];

  useEffect(() => {
    let allCounties = [];
    let allCategories = [];
    let nationalCategories = [];

    // to display votes per category
    const testURLCategory =
      process.env.REACT_APP_DASHBOARD_URL +"?view=vote_category";
    axios.get(testURLCategory).then((response) => {
      let arr = [];
      response.data.map((elem) => {
        let container = {
          category: elem.category_name,
          votes: elem.count,
        };
        arr.push(container);
      });
      arr.sort((a, b) => a.votes - b.votes);
      setData(arr);
    });
    // to display votes per county
    const testURLCounty =
      process.env.REACT_APP_DASHBOARD_URL +"?view=vote_county";
    axios.get(testURLCounty).then((response) => {
      let arr = [];
      response.data.map((elem) => {
        let container = {
          county: elem.county_name,
          countyVote: elem.count,
        };
        arr.push(container);
      });
      arr.sort((a, b) => a.countyVote - b.countyVote);
      setDataTest(arr);
    });

    //to display device count
    const testURLDeviceCount =
      process.env.REACT_APP_DASHBOARD_URL +"?view=device_count";
    axios.get(testURLDeviceCount).then((response) => {
      response.data.map((elem) => {
        if (elem.device === "mobile") {
          setMobileVotes(elem.count);
        } else if (elem.device === "desktop") {
          setDesktopVotes(elem.count);
        }
      });
    });

    //to display total voters (unique)
    const testURLVoters =
      process.env.REACT_APP_DASHBOARD_URL +"?view=distinct_votes";
    axios.get(testURLVoters).then((response) => {
      response.data.map((elem) => {
        setTotalVoters(elem.unique_voters);
      });
    });

    //to display total votes
    const testURLVotes =
      process.env.REACT_APP_DASHBOARD_URL +"?view=total_vote_count";
    axios.get(testURLVotes).then((response) => {
      response.data.map((elem) => {
        setTotalVotes(elem.count);
      });
    });

    //to display listOfCounties
    const testURLCounties =
      process.env.REACT_APP_DASHBOARD_URL +"?view=county";
    axios.get(testURLCounties).then((response) => {
      response.data.map((elem) => {
        let container = {
          countyName: elem.county_name,
          countyId: elem.county_id,
        };
        allCounties.push(container);
      });
      setCounties(allCounties);
    });

    //to display listOfCategories
    const testURLCategories =
      process.env.REACT_APP_DASHBOARD_URL +"?view=category";
    axios.get(testURLCategories).then((response) => {
      response.data.map((elem) => {
        if (
          elem.category_name !== "Best Seafood Experience" &&
          elem.category_name !== "Best Cocktail Experience" &&
          elem.category_name !== "Best Digital Marketing" &&
          elem.category_name !== "Best Private Dining & Club Restaurant" &&
          elem.category_name !== "Best Cookery School"
        ) {
          let container = {
            categoryName: elem.category_name,
            categoryId: elem.category_id,
            winners: [],
          };
          allCategories.push(container);
        }
      });
      setCategories(allCategories);
    });
    //to display listOfNationalCategories
    const testURLNationalCategories =
      process.env.REACT_APP_DASHBOARD_URL +"?view=national_category";
    axios.get(testURLNationalCategories).then((response) => {
      response.data.map((elem) => {
        let container = {
          categoryName: elem.category_name,
          places: [elem.place_name],
        };
        if (
          nationalCategories.filter(
            (e) => e.categoryName === elem.category_name
          ).length > 0
        ) {
          nationalCategories.map((el) => {
            if (el.categoryName === elem.category_name) {
              el.places.push(elem.place_name);
            }
          });
        } else {
          nationalCategories.push(container);
        }
      });
      setNationalCateg(nationalCategories);
    });

    // if (data.length === 0) {
    //   const testURL =
    //     "https://33pmnf9gej.execute-api.eu-west-1.amazonaws.com/dev/RAI_Winners";
    //   axios.get(testURL).then((response) => {
    //     let votes = response.data.Votes;
    //     let chartData = [];
    //     let chartDataTest = [];
    //     let totalVoteCount = 0;
    //     let allCategories = [];
    //     let nationalCategories = [];
    //     for (let vote in votes) {
    //       let category = {
    //         category: vote,
    //         votes: 0,
    //       };
    //       let countyVotes = votes[vote];
    //       let categoryTotalVoteCount = 0;
    //       let t = [];
    //       for (let countyVote in countyVotes) {
    //         let obj = {
    //           county: countyVote,
    //           votes: 0,
    //         };
    //         const r = allCategories.find(
    //           (elem) =>
    //             elem.category === vote && elem.winn.county === countyVote
    //         );
    //         if (!r) {
    //         }
    //         // if (!allCounties.includes(countyVote)) {
    //         //   allCounties.push(countyVote);
    //         // }
    //         let restaurant = countyVotes[countyVote];
    //         let xMax = Math.max.apply(
    //           null,
    //           Object.entries(countyVotes[countyVote]).map(function (o) {
    //             return o[1];
    //           })
    //         );
    //         var maxXObject = Object.entries(countyVotes[countyVote]).filter(
    //           function (o) {
    //             return o[1] === xMax;
    //           }
    //         );
    //         t.push({
    //           county: countyVote,
    //           restaurant: maxXObject,
    //         });
    //         for (let restaurantVotes in restaurant) {
    //           categoryTotalVoteCount += restaurant[restaurantVotes];
    //         }
    //         obj.votes = categoryTotalVoteCount;
    //         const result = chartDataTest.find(
    //           ({ county }) => county === obj.county
    //         );
    //         if (!result) chartDataTest.push(obj);
    //         else {
    //           chartDataTest.map((elem) => {
    //             if (elem.county === obj.county) {
    //               elem.votes += obj.votes;
    //             }
    //           });
    //         }
    //       }
    //       let objWin = {
    //         category: vote,
    //         restaurants: t,
    //       };
    //       if (
    //         objWin.category !== "Best Seafood Experience" &&
    //         objWin.category !== "Best Cocktail Experience" &&
    //         objWin.category !== "Best Digital Marketing" &&
    //         objWin.category !== "Best Private Dining & Club Restaurant" &&
    //         objWin.category !== "Best Cookery School"
    //       ) {
    //         allCategories.push(objWin);
    //       } else {
    //         nationalCategories.push(objWin);
    //       }
    //       category.votes = categoryTotalVoteCount;
    //       let csvCategory = {
    //         category: vote,
    //         votes: categoryTotalVoteCount,
    //       };
    //       chartData.push(category);
    //       csvDataChart.push(csvCategory);
    //     }

    //     chartData.sort(function (a, b) {
    //       return parseFloat(a.votes) - parseFloat(b.votes);
    //     });
    //     chartDataTest.map((elem, index) => {
    //       const result = csvDataChart.find(
    //         (csvData) =>
    //           csvData && csvData.county && csvData.county === elem.county
    //       );
    //       if (!result) {
    //         csvDataChart[index]["county"] = elem.county;
    //         csvDataChart[index]["countyVote"] = elem.votes;
    //       } else {
    //         csvDataChart.map((obj) => {
    //           if (elem.county === obj.county) {
    //             elem.countyVote += obj.countyVote;
    //           }
    //         });
    //       }
    //     });
    //     chartDataTest.sort(function (a, b) {
    //       return parseFloat(a.votes) - parseFloat(b.votes);
    //     });
    //     csvDataChart[0]["totalvotes"] = totalVotesCounter;
    //     csvDataChart[0]["desktopvotes"] = desktopD;
    //     csvDataChart[0]["mobilevotes"] = mobileD;
    //     csvDataChart[0]["totalvoters"] = totalVotersUnique;
    //     // setCounties(allCounties);
    //     // setCategories(allCategories);
    //     setNationalCateg(nationalCategories);
    //     // setData(chartData);
    //     setCsvData(csvDataChart);
    //     // setDataTest(chartDataTest);
    //     return response;
    //   });

    //   const statisticsURL =
    //     "https://33pmnf9gej.execute-api.eu-west-1.amazonaws.com/dev/RAI_Statistics";
    //   axios.get(statisticsURL).then((response) => {
    //     let nrOfVotesForToday = 0;
    //     let nrOfVotesForYesterday = 0;
    //     let nrOfVotesForThisWeekToday = 0;
    //     let nrOfVotesForLastWeek = 0;
    //     let todayDate = new Date();
    //     const timeStamp = new Date().getTime();
    //     const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
    //     let yesterdayDate = new Date(yesterdayTimeStamp);
    //     var today =
    //       todayDate.getFullYear() +
    //       "-" +
    //       ("0" + (todayDate.getMonth() + 1)).slice(-2) +
    //       "-" +
    //       ("0" + todayDate.getDate()).slice(-2);
    //     var yesterday =
    //       yesterdayDate.getFullYear() +
    //       "-" +
    //       ("0" + (yesterdayDate.getMonth() + 1)).slice(-2) +
    //       "-" +
    //       ("0" + yesterdayDate.getDate()).slice(-2);
    //     let votes = response.data;
    //     let dates = getCurrentWeekAndLastWeekStartDateAndEndDate();
    //     for (let vote in votes) {
    //       if (votes[vote].voteTimeStamp.includes(today)) {
    //         nrOfVotesForToday++;
    //       }
    //       if (votes[vote].voteTimeStamp.includes(yesterday)) {
    //         nrOfVotesForYesterday++;
    //       }
    //       if (
    //         dates.currentWeekDates.includes(
    //           votes[vote].voteTimeStamp.substring(0, 10)
    //         )
    //       ) {
    //         nrOfVotesForThisWeekToday++;
    //       }
    //       if (
    //         dates.lastWeekDates.includes(
    //           votes[vote].voteTimeStamp.substring(0, 10)
    //         )
    //       ) {
    //         nrOfVotesForLastWeek++;
    //       }
    //     }
    //     if (nrOfVotesForYesterday !== 0) {
    //       let percentage =
    //         ((nrOfVotesForToday - nrOfVotesForYesterday) /
    //           nrOfVotesForYesterday) *
    //         100;
    //       if (percentage % 1 !== 0)
    //         setDailyVotesChangeProcentage(percentage.toFixed(1));
    //       else setDailyVotesChangeProcentage(percentage);
    //     } else if (nrOfVotesForYesterday === 0) {
    //       let percentage = (nrOfVotesForToday - nrOfVotesForYesterday) * 100;
    //       if (percentage % 1 !== 0)
    //         setDailyVotesChangeProcentage(percentage.toFixed(1));
    //       else setDailyVotesChangeProcentage(percentage);
    //     }
    //     if (nrOfVotesForLastWeek !== 0) {
    //       let percentage =
    //         ((nrOfVotesForThisWeekToday - nrOfVotesForLastWeek) /
    //           nrOfVotesForLastWeek) *
    //         100;
    //       if (percentage % 1 !== 0)
    //         setWeeklyVotesChangePercentage(percentage.toFixed(1));
    //       else setWeeklyVotesChangePercentage(percentage);
    //     } else if (nrOfVotesForLastWeek === 0) {
    //       let percentage =
    //         (nrOfVotesForThisWeekToday - nrOfVotesForLastWeek) * 100;
    //       if (percentage % 1 !== 0)
    //         setWeeklyVotesChangePercentage(percentage.toFixed(1));
    //       else setWeeklyVotesChangePercentage(percentage);
    //     }
    //   });
    // }
  }, []);

  useEffect(() => {
    if (data.length > 0 && dataTest.length > 0) {
      data.map((elem) => {
        csvDataChart.push(elem);
      });
      dataTest.map((elem, index) => {
        const result = csvDataChart.find(
          (csvData) =>
            csvData && csvData.county && csvData.county === elem.county
        );

        if (!result && csvDataChart[index]) {
          csvDataChart[index]["county"] = elem.county;
          csvDataChart[index]["countyVote"] = elem.countyVote;
        } else if (result) {
          csvDataChart.map((obj) => {
            if (elem.county === obj.county) {
              elem.countyVote += obj.countyVote;
            }
          });
        } else if (!csvDataChart[index]) {
          csvDataChart.push(elem);
        }
      });
      csvDataChart[0]["totalvotes"] = totalVotes;
      csvDataChart[0]["desktopvotes"] = desktopVotes;
      csvDataChart[0]["mobilevotes"] = mobileVotes;
      csvDataChart[0]["totalvoters"] = totalVoters;
    }
    // setCsvData(csvDataChart);
  }, [data, dataTest, mobileVotes, desktopVotes, totalVoters, totalVotes]);

  const getCurrentWeekAndLastWeekStartDateAndEndDate = () => {
    var today = new Date();
    var firstDayOfTheCurrentWeek = today.getDate() - today.getDay() + 1; // First day is the day of the month - the day of the week

    firstDayOfTheCurrentWeek = new Date(
      today.setDate(firstDayOfTheCurrentWeek)
    );

    const timeStamp = new Date(firstDayOfTheCurrentWeek).getTime();

    let lastWeekDates = [];
    let currentWeekDates = [];
    for (let index = 1; index <= 7; index++) {
      const yesterdayTimeStamp = timeStamp - index * (24 * 60 * 60 * 1000);
      let yesterdayDate = new Date(yesterdayTimeStamp);
      yesterdayDate =
        yesterdayDate.getFullYear() +
        "-" +
        ("0" + (yesterdayDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + yesterdayDate.getDate()).slice(-2);
      lastWeekDates.push(yesterdayDate);

      if (index === 1) {
        const tomorowTimeStamp = timeStamp;
        let tomorowDate = new Date(tomorowTimeStamp);
        tomorowDate =
          tomorowDate.getFullYear() +
          "-" +
          ("0" + (tomorowDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + tomorowDate.getDate()).slice(-2);
        currentWeekDates.push(tomorowDate);
      }
      if (index <= 7 && index > 1) {
        const tomorowTimeStamp =
          timeStamp + (index - 1) * (24 * 60 * 60 * 1000);
        let tomorowDate = new Date(tomorowTimeStamp);
        tomorowDate =
          tomorowDate.getFullYear() +
          "-" +
          ("0" + (tomorowDate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + tomorowDate.getDate()).slice(-2);
        currentWeekDates.push(tomorowDate);
      }
    }

    let dates = {
      currentWeekDates: currentWeekDates,
      lastWeekDates: lastWeekDates,
    };
    return dates;
  };
  const theme = {
    axis: {
      domain: {
        line: {
          stroke: "#777777",
          strokeWidth: 1,
        },
      },
    },
  };
  return (
    // <div>
    <div className={style.container}>
      <TopMenu data={data} csvData={csvData} headers={headers} />
      <div className={style.secondContainer}>
        <div className={style.firstBox}>
          <div className={style.totalVotes}>
            <span className={style.verticalBar}></span>
            <div>
              <div className={style.subtitle}>Total Votes</div>
              <div className={style.infoTotalVotes}>{totalVotes}</div>
            </div>
          </div>
          <div className={style.totalVotes}>
            <span className={style.verticalBar}></span>
            <div>
              <div className={style.subtitle}>Total Voters</div>
              <div className={style.infoTotalVotes}>{totalVoters}</div>
            </div>
          </div>
          <div className={style.votesPerWeekAvg}>
            <span className={style.verticalBar}></span>
            <div>
              <div className={style.subtitle}>Votes per Week AVG</div>
              <div className={style.infoVotesPerWeek}>{averageDailyVotes}</div>
              <div className={style.additionalInfo}>
                Up {weeklyVotesChangePercentage}% from last Week
              </div>
            </div>
          </div>
          <div className={style.votesPerDayAvg}>
            <span className={style.verticalBar}></span>
            <div>
              <div className={style.subtitle}>Votes per Day AVG</div>
              <div className={style.infoVotesPerDay}>{averageWeeklyVotes}</div>
              <div className={style.additionalInfo}>
                Up {dailyVotesChangePercentage}% from yesterday
              </div>
            </div>
          </div>
        </div>
        <div className={style.secondBox}>
          <div className={style.votesPerWeekTitle}>Votes Per Category</div>
          <div className={style.barChart}>
            <ResponsiveBar
              data={data}
              keys={["votes"]}
              indexBy="category"
              margin={{ top: 50, right: 50, bottom: 50, left: 150 }}
              padding={0.3}
              layout="horizontal"
              colors={["#31088880"]}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickRotation: 0,
                legend: "",
                legendPosition: "middle",
                legendOffset: 32,
                tickSize: 0,
              }}
              theme={theme}
              enableLabel={false}
              enableGridY={false}
              axisLeft={{
                format: (v) => {
                  return v.length > 10 ? (
                    <tspan
                      style={{
                        marginLeft: "0px",
                        marginRight: "auto",
                        display: "flex",
                      }}
                    >
                      {v.substring(0, 19) + "..."}
                      <title>{v}</title>
                    </tspan>
                  ) : (
                    v
                  );
                },

                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendPosition: "middle",
                legendOffset: -60,
              }}
            />
          </div>
          <div className={style.votesPerWeekTitle}>Votes Per County</div>
          <div className={style.barChart}>
            <ResponsiveBar
              data={dataTest}
              keys={["countyVote"]}
              indexBy="county"
              margin={{ top: 50, right: 50, bottom: 50, left: 150 }}
              padding={0.3}
              layout="horizontal"
              colors={["#31088880"]}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickRotation: 0,
                legend: "",
                legendPosition: "middle",
                legendOffset: 32,
                tickSize: 0,
              }}
              theme={theme}
              enableLabel={false}
              enableGridY={false}
              axisLeft={{
                format: (v) => {
                  return v.length > 10 ? (
                    <tspan
                      style={{
                        marginLeft: "0px",
                        marginRight: "auto",
                        display: "flex",
                      }}
                    >
                      {v.substring(0, 19) + "..."}
                      <title>{v}</title>
                    </tspan>
                  ) : (
                    v
                  );
                },

                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendPosition: "middle",
                legendOffset: -60,
              }}
            />
          </div>
        </div>
        <div className={style.thirdBox}>
          <div className={style.desktopVotes}>
            <div className={style.votesInfo}>{desktopVotes}</div>
            <div className={style.votesSubtitle}>Desktop Votes</div>
          </div>
          <div className={style.mobileVotes}>
            <div className={style.votesInfo}>{mobileVotes}</div>
            <div className={style.votesSubtitle}>Mobile Votes</div>
          </div>
        </div>
      </div>
      <div className={style.thirdContainer}>
        <div className={style.winnersFirstBox}>
          <div className={style.titleFirstBox}>Counties:</div>
          {counties.map((elem, key) => (
            <div
              key={key}
              className={
                elem.countyId === selectedCounty
                  ? style.countySelected
                  : style.county
              }
              onClick={() => setSelectedCounty(elem.countyId)}
            >
              <FontAwesomeIcon icon={faLocationDot} className={style.icon} />
              {elem.countyName}
            </div>
          ))}
        </div>
        <div className={style.winnersSecondBox}>
          <div className={style.titleFirstBox}>National Category Awards:</div>

          {nationalCateg.map((elem, key) => (
            <>
              <div key={key} className={style.category}>
                <FontAwesomeIcon icon={faTrophy} className={style.icon} />
                {elem.categoryName}
              </div>
              {elem.places.length > 0 &&
                elem.places.map((el, k) => {
                  return (
                    <div key={k} className={style.winnerRestaurant}>
                      <FontAwesomeIcon
                        icon={faStar}
                        className={style.starIcon}
                      />{" "}
                      {el}
                    </div>
                  );
                })}
              {elem.places.length === 0 ? (
                <div className={style.noNominations}>
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className={style.iconExclamation}
                  />
                  This category has no nominations!
                </div>
              ) : (
                <></>
              )}

              {/* {elem.restaurants.map((rest) => {
                if (rest.county === selectedCounty) {
                  return rest.restaurant.length === 1 ? (
                    <div className={style.winnerRestaurant}>
                      <FontAwesomeIcon
                        icon={faStar}
                        className={style.starIcon}
                      />{" "}
                      {rest.restaurant[0][0]}
                    </div>
                  ) : (
                    rest.restaurant.map((e, key) => {
                      return (
                        <div key={key} className={style.winnerRestaurant}>
                          <FontAwesomeIcon
                            icon={faStar}
                            className={style.starIcon}
                          />{" "}
                          {e[0]}
                        </div>
                      );
                    })
                  );
                }
              })} */}
              {/* {selectedCounty !== "" &&
              !elem.restaurants.some(
                (person) => person.county === selectedCounty
              ) ? (
                <div className={style.noNominations}>
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className={style.iconExclamation}
                  />
                  This category has no nominations for the selected county!
                </div>
              ) : (
                <></>
              )} */}
            </>
          ))}
          <div className={style.titleFirstBox}>Main Category Awards:</div>
          {categories.map((elem, key) => (
            <>
              <div key={key} className={style.category}>
                <FontAwesomeIcon icon={faTrophy} className={style.icon} />
                {elem.categoryName}
              </div>
              {elem.winners &&
                elem.winners.map((rest, k) => {
                  return (
                    <div className={style.winnerRestaurant} key={k}>
                      <FontAwesomeIcon
                        icon={faStar}
                        className={style.starIcon}
                      />{" "}
                      {rest}
                    </div>
                  );
                })}
              {selectedCounty !== "" && elem.winners.length === 0 ? (
                <div className={style.noNominations}>
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className={style.iconExclamation}
                  />
                  This category has no nominations for the selected county!
                </div>
              ) : (
                <></>
              )}
            </>
          ))}
          {/* {categories.map((elem, key) => (
            <>
              <div key={key} className={style.category}>
                <FontAwesomeIcon icon={faTrophy} className={style.icon} />
                {elem.category}
              </div>
              {elem.restaurants.map((rest) => {
                if (rest.county === selectedCounty) {
                  return rest.restaurant.length === 1 ? (
                    <div className={style.winnerRestaurant}>
                      <FontAwesomeIcon
                        icon={faStar}
                        className={style.starIcon}
                      />{" "}
                      {rest.restaurant[0][0]}
                    </div>
                  ) : (
                    rest.restaurant.map((e, key) => {
                      return (
                        <div key={key} className={style.winnerRestaurant}>
                          <FontAwesomeIcon
                            icon={faStar}
                            className={style.starIcon}
                          />{" "}
                          {e[0]}
                        </div>
                      );
                    })
                  );
                }
              })}
              {selectedCounty !== "" &&
              !elem.restaurants.some(
                (person) => person.county === selectedCounty
              ) ? (
                <div className={style.noNominations}>
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className={style.iconExclamation}
                  />
                  This category has no nominations for the selected county!
                </div>
              ) : (
                <></>
              )}
            </>
          ))} */}
        </div>
        <div className={style.footer}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
