import style from "./TopMenu.module.scss";
import ira_mobile from "../../resources/ira_mobile.png";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
const TopMenu = ({ data, csvData, headers }) => {
  const navigate = useNavigate();
  const signOut = () => {
    localStorage.removeItem(
      "CognitoIdentityServiceProvider.2g50et56719gr13ehtd5sdvj32.b3877fe9-7c1c-44a6-a02b-0b2ab7b2f1cb.accessToken"
    );
    navigate("/login");
  };
  return (
    <div className={style.container}>
      <div className={style.firstElement}>
        {/* <img src={ira_mobile} className={style.image} alt="ira" /> */}
      </div>
      <div className={style.secondElement}>
        <div className={style.csvContainer}>
          {data && data.length > 0 && (
            <div>
              <CSVLink
                data={csvData}
                headers={headers}
                filename={"votes.csv"}
                className={style.exportBtn}
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  className={style.iconDownload}
                />
                Export to CSV
              </CSVLink>
            </div>
          )}
        </div>
        <div>
          <GoSignOut
            onClick={signOut}
            style={{
              marginRight: "10px",
              paddingBottom: "3px",
              color: "#fff",
              cursor: "pointer",
            }}
            color="#fff"
            size="30"
          ></GoSignOut>
        </div>
        <div
          onClick={signOut}
          style={{
            marginRight: "10px",
            paddingBottom: "3px",
            color: "#fff",
            cursor: "pointer",
          }}
        >
          Sign Out
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
