import style from "./Footer.module.scss";
import expleo from "../../resources/powered-by-expleod-logo.png";

const Footer = () => {
  return (
    <div className={`${style["footer-container"]}`}>
      <a href="https://expleogroup.com/" target="_blank" rel="noreferrer">
        <img
          src={expleo}
          className={style.image}
          alt="Powered by Expleo Logo"
        />
      </a>
    </div>
  );
};

export default Footer;
