import React, { useEffect, useState } from "react";
import style from "./Form.module.scss";
import Dropdown from "../../../components/Dropdown/Dropdown.jsx";
import GoogleMapsPlaceSearch from "../../../components/GoogleMaps/GoogleMapsSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Footer/Footer";
import Recaptcha from "../../Recaptcha/Recaptcha";
import { useData } from "../../../DataProvider";
import { Card, CardContent } from "@mui/material";

const Form = ({
  specialCharacterFirstName,
  specialCharacterLastName,
  specialCharacterEmail,
  specialCharacterCategoryName,
  specialCharacterdescriptionValue,
  place,
  onChangePlace,
  country,
  onChangeCountry,
  county,
  onChangeCounty,
  city,
  onChangeCity,
  firstName,
  categoryName,
  lastName,
  email,
  descriptionValue,
  onChangeFirstName,
  onChangeLastName,
  onChangeEmail,
  onChangeCategoryName,
  chosenCategory,
  onChangeCategory,
  onChangeOverEighteen,
  onChangeSubscription,
  onChangeCategoryDescription,
  submitBtn,
  setCategoryId,
}) => {
  const [categoryDefinition, setCategoryDefinition] = useState("");

  useEffect(() => {
    const getDefinition = () => {
      let categoey = categories.find((x) => x.value === chosenCategory);
      setCategoryDefinition(
        categoey && categoey.definition ? categoey.definition : ""
      );
      setCategoryId(categoey && categoey.categoryId ? categoey.categoryId : "");
    };
    if (chosenCategory != "") {
      getDefinition();
    }
  }, [chosenCategory]);

  const { counties } = useData();

  const categories = [
    {
      label: "Please select nomination category",
      value: "default",
      definition: "",
    },
    {
      label: "Best Restaurant – Sponsored by Tindal Wine Merchants",
      value: "Best Restaurant",
      categoryId: "1",
      definition:
        "We are looking for a restaurant that ticks all the boxes – exemplary customer service, wonderful atmosphere and of course, outstanding food. Nominate a place that you would recommend to anyone who wants to impress their guests, and that you would return to time and time again.",
    },
    {
      label: "Best Chef – Sponsored by BWG Foodservice",
      value: "Best Chef",
      categoryId: "2",
      definition:
        "This award represents the chef who demonstrates an immense passion for food – someone who is committed to quality and the pursuit of creative new dining experiences. The winner will introduce their diners to exciting new flavour combinations, and consistently produces top quality dishes.",
    },
    {
      label: "Best Gastro Pub – Sponsored by Elavon",
      value: "Best Gastro Pub",
      categoryId: "3",
      definition:
        "This award recognises the gastro pub that offers an innovative menu of food which suitably complements their drink selection. The Best Gastropub will provide restaurant quality dining, whilst not compromising on the traditional ‘pub’ atmosphere. Table service is not a necessity – some gastropubs require food to be ordered at the bar. Customer service should still be of a high standard.",
    },
    {
      label: "Best Wine Experience – Sponsored by Gilbeys with Bibendum",
      value: "Best Wine Experience",
      categoryId: "4",
      definition:
        "This award recognises the restaurant that prides itself on its wine selection. An interesting array of wines will be on offer to cater for a wide range of wine lovers, and will be appropriate for the type of cuisine served. There should be a well-chosen selection from quality producers that provides a thematic match to the menu in terms of both price and style.",
    },
    {
      label: "Best Hotel & Guesthouse Restaurant – Sponsored by Frylite",
      value: "Best Hotel & Guesthouse Restaurant",
      categoryId: "5",
      definition:
        "The best hotel & guesthouse restaurant will encompass all the qualities of great hospitality – exemplary customer service, top quality food and a welcoming and relaxing atmosphere. We are looking for somewhere that extends its outstanding hospitality to both residents and walk-in guests alike.",
    },
    {
      label: "Best Restaurant Manager – Sponsored by AIB MS",
      value: "Best Restaurant Manager",
      categoryId: "6",
      definition:
        "This award recognises the individual that oversees the day to day operation of the restaurant with enthusiasm and expertise. They will be an exceptional leader for their team and will be able to handle any situation that arises. They will demonstrate strong business acumen, working knowledge of the industry and an intimate knowledge of the menu and wines being offered. The restaurant manager will be the face of the establishment and will have a good rapport with both new and returning customers.",
    },
    {
      label: "Best Casual Dining – Sponsored by Just Eat",
      value: "Best Casual Dining",
      categoryId: "7",
      definition:
        "We are on the search for the best casual dining establishment offering top quality food, great customer service and genuine hospitality. Whether it is table service or order at a counter, the atmosphere should be relaxed and informal. Décor, style of food and culture of each establishment will vary widely but should all complement each other within each restaurant.",
    },
    {
      label: "Best Newcomer – Sponsored by Monin",
      value: "Best Newcomer",
      categoryId: "8",
      definition:
        "This award represents the best break-through establishment that has been in operation since 2020 (opened in/after June 2020) – offering an impressive dining experience, innovative menu and has had significant impact in the industry within a short time. They will have proven marketing abilities and a loyal customer following.",
    },
    {
      label: "Best Customer Service – Sponsored by Dolmen Insurance Brokers",
      value: "Best Customer Service",
      categoryId: "9",
      definition:
        "The winner of this award will be one that consistently goes above and beyond the call of duty to its customers. Staff will be attentive, friendly and prompt, but not overbearing. It will be evident that they have been trained in the art of great customer service, and they will also be knowledgeable of both the food and wine menus. They will be professional and well presented at all times, and represent the customs and culture of the restaurant.",
    },
    {
      label: "Pub of the Year – Sponsored by Jameson",
      value: "Pub of the Year",
      categoryId: "10",
      definition:
        "The Pub of the Year is one that caters to all tastes – from whiskey lovers to craft beer enthusiasts. A wide selection of quality beverages should be on offer. The atmosphere should be comfortable and welcoming with that traditional ‘pub feel’. Staff will value their customers and be able to recommend drinks without hesitation. The winner should be popular with locals and visitors alike, and should inspire high standards.",
    },
    {
      label: "Best Emerging Irish Cuisine",
      value: "Best Emerging Irish Cuisine",
      categoryId: "11",
      definition:
        "This award will go to the restaurant that displays a genuine commitment to actively influencing the future of Irish cuisine by encouraging us to try something new, while simultaneously reminding us of our heritage. The menu will showcase the best of Irish produce from the land and sea. Traditional Irish dishes may be rejuvenated with a modern twist. Unique and modern ingredients, methods and styles of cooking may be used.",
    },
    {
      label:
        "Best World Cuisine – Sponsored by Stafford Lynch – San Pellegrino",
      value: "Best World Cuisine",
      categoryId: "12",
      definition:
        "We are looking for a restaurant that provides a genuine international experience for their diners. The winner should provide outstanding food, excellent customer service and a real insight into their culture through both the dishes and the decor. We want to see what global delights are available right on our own doorstep.",
    },
    {
      label: "Best Café – Sponsored by Illy",
      value: "Best Café",
      categoryId: "13",
      definition:
        "This award represents the establishment that strives to provide the best coffee and dining experience, using ethically sourced ingredients and delivering outstanding customer service. Knowledgeable baristas, great quality food and a warm and inviting atmosphere. Nominate your go-to place for a casual lunch with friends, or a quick bite on the go.",
    },
    {
      label: "Best ‘Free From’ – Sponsored by Peroni Libera",
      value: "Best ‘Free From’",
      categoryId: "14",
      definition: `This award goes to the establishment offering mouth-watering ‘free from’ options within their menus – meals free from the main list of allergens* and whose presentation highlights the unique qualities of the ingredients.
      *Dishes must be ‘Free From’ at least 1 of the following allergens: celery, crustaceans, eggs, fish, gluten, peanuts, soybeans, milk and products thereof (including lactose), mustard, nuts, sesame seed, sulphur dioxide and sulphites, lupin and molluscs.
      Please note that sugar free and ‘meat free’ meals are also eligible for nomination.
      Entrants should have a variety of ‘free from’ meal options on their menus to cater for a ‘free from’ audience, however their menus do not need to be entirely ‘free from’.`,
    },
    {
      label: "Local Food Hero – Sponsored by The Irish Times",
      value: "Local Food Hero",
      categoryId: "15",
      definition: `Please note that this is a Regional Category – there will be no county winners – only one winner per region
      The Local Food Hero award recognises the individual that promotes food culture in their locality and inspires people to get involved with food. They may be actively encouraging the restaurants in their area to use local produce, or perhaps they are working to attract food tourists by creating food trails and markets. Or maybe they produce the finest quality ingredients.`,
    },
    {
      label: "Best Cocktail Experience – Sponsored by Coca Cola HBC",
      value: "Best Cocktail Experience",
      categoryId: "16",
      definition:
        "This award goes to the restaurant or bar which brings a creative and exciting approach to cocktails, coupled with flair and imaginative presentation. Nominees for Best Cocktail Experience will be invited to submit an original cocktail recipe and the next stage is a live cocktail competition where the drinks will be made and presented to a panel of experts.",
    },
    {
      label:
        "Best Private Dining & Club Restaurant – Sponsored by Wild Irish Game",
      value: "Best Private Dining & Club Restaurant",
      categoryId: "17",
      definition:
        "This award goes to the private members clubs – including golf clubs, sailing clubs, private dining clubs and tennis clubs – which offer their members a dining experience which demonstrates outstanding value, excellent food, appropriate service, genuine hospitality and an appropriate overall dining experience.",
    },
    {
      label: "Best Cookery School – Sponsored by Avonmore",
      value: "Best Cookery School",
      categoryId: "18",
      definition:
        "This award goes to the cookery school who contributes greatly to food culture in Ireland by educating its students in nutrition and best practice in the preparation, cooking and presentation of food to a high standard. The winning school will have the ability to foster a passion for culinary arts within its students from experienced chefs and tutors. The school will produce a high standard of graduates who have the necessary skills and techniques to go on and become successful chefs.",
    },
    {
      label: "Best Seafood Experience – Sponsored by Musgrave",
      value: "Best Seafood Experience",
      categoryId: "19",
      definition:
        "This award goes to the restaurant offering an mouth-watering selection of fresh Irish seafood and whose presentation highlights the unique qualities of the ingredients. Locally sourced seafood is important for both ensuring freshness, and supporting the local economy.",
    },
    {
      label: "Best Digital Marketing",
      value: "Best Digital Marketing",
      categoryId: "20",
      definition:
        "This award goes to the restaurant whose social media design, communication and presentation is creative, innovative and on-trend. Nominate a restaurant that you actively follow across various online platforms, and that engages and delights their customers in novel ways.",
    },
  ];

  const [notARobot, setNotARobot] = useState(false);
  const getResponse = (isNotRobot) => {
    console.log("The response is " + isNotRobot);
    setNotARobot(isNotRobot);
  };

  return (
    <div className={style.container}>
      <div className={style.title}>So, where would you like to nominate</div>
      <div className={style.subTitle}>Just start typing below</div>
      <div className={style.placesearch}>
        <GoogleMapsPlaceSearch
          placeholder="Name of Hospitality Business"
          value={place}
          onChange={onChangePlace}
        />
      </div>
      <div className={style.ckeckboxes}>
        <label className={style.containerRadio} htmlFor="IE">
          Republic of Ireland
          <input
            type="radio"
            name="country"
            id="IE"
            value="IE"
            onChange={(e) => onChangeCountry(e.target.value)}
            checked={country === "IE"}
          />
          <span className={style.checkmark}></span>
        </label>
        <label className={style.containerRadio} htmlFor="Northern Ireland">
          Northern Ireland
          <input
            type="radio"
            name="country"
            id="Northern Ireland"
            value="GB"
            onChange={(e) => onChangeCountry(e.target.value)}
            checked={country === "GB"}
          />
          <span className={style.checkmark}></span>
        </label>
        <label
          className={style.containerRadio}
          htmlFor="My establishment is not listed"
        >
          My establishment is not listed
          <input
            type="radio"
            name="country"
            id="My establishment is not listed"
            value="not_listed"
            onChange={(e) => onChangeCountry(e.target.value)}
            checked={country === "not_listed"}
          />
          <span className={style.checkmark}></span>
        </label>
      </div>
      <div className={style.dropdownInline}>
        <Dropdown
          label="Category"
          options={categories}
          value={chosenCategory}
          onChange={onChangeCategory}
        />
        {categoryDefinition != "" && (
          <div>
            <Card className={style.cardStyle}>
              <CardContent className={style.cardContetStyle}>
                {categoryDefinition}
              </CardContent>
            </Card>
          </div>
        )}
      </div>

      {chosenCategory &&
        (chosenCategory === "Best Chef" ||
          chosenCategory === "Best Restaurant Manager" ||
          chosenCategory === "Best Hotel & Guesthouse Restaurant" ||
          chosenCategory === "Local Food Hero") && (
          <div className={style.extraField}>
            <div className={style.labelLeft}>
              <label className={style.label}>
                {chosenCategory + " "} Name{" "}
                <sup className={style.required}>*</sup>
              </label>
              <input
                placeholder=""
                className={style.inputField}
                value={categoryName}
                onChange={(e) => onChangeCategoryName(e.target.value)}
              />
              <span className={style.icon}>
                {categoryName !== "" ? (
                  specialCharacterCategoryName ? (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={style.wrongIcon}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className={style.checkIcon}
                    />
                  )
                ) : (
                  <></>
                )}
              </span>
            </div>
            {chosenCategory === "Local Food Hero" && (
              <div className={style.labelLeft}>
                <label className={style.label}>
                  {chosenCategory + " "} Description{" "}
                  <sup className={style.required}>*</sup>
                </label>
                <textarea
                  placeholder=""
                  className={style.inputField}
                  value={descriptionValue}
                  onChange={(e) => onChangeCategoryDescription(e.target.value)}
                ></textarea>
                <span className={style.icon}>
                  {descriptionValue !== "" ? (
                    specialCharacterdescriptionValue ? (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className={style.wrongIcon}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className={style.checkIcon}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            )}
          </div>
        )}
      <div className={style.dropdownInline}>
        <Dropdown
          label="County"
          options={[
            { value: "default", label: "Please select business county" },
            ...counties.map((item) => ({ value: item, label: item })),
          ]}
          value={county}
          onChange={(e) => onChangeCounty(e.target.value)}
        />
        <div>
          <label className={style.label}>
            City / Town / Village <sup className={style.required}>*</sup>
          </label>
          <input
            placeholder="City"
            className={style.inputField}
            value={city}
            onChange={(e) => onChangeCity(e.target.value)}
          />
        </div>
      </div>
      <div className={`${style.title} ${style.titleMarginTop}`}>
        Your personal details
      </div>
      <div className={style.paragraph}>
        pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat
        vivamus at augue eget arcu dictum varius
      </div>
      <div className={style.personalInfo}>
        <div className={style.labelLeft}>
          <label className={style.label}>
            First Name <sup className={style.required}>*</sup>
          </label>
          <input
            placeholder="John"
            className={style.inputField}
            value={firstName}
            onChange={(e) => onChangeFirstName(e.target.value)}
          />
          <span className={style.icon}>
            {firstName !== "" ? (
              specialCharacterFirstName ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className={style.wrongIcon}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={style.checkIcon}
                />
              )
            ) : (
              <></>
            )}
          </span>
        </div>
        <div className={style.labelLeft}>
          <label className={style.label}>
            Surname <sup className={style.required}>*</sup>
          </label>
          <input
            placeholder="Smith"
            className={style.inputField}
            value={lastName}
            onChange={(e) => onChangeLastName(e.target.value)}
          />
          <span className={style.icon}>
            {lastName !== "" ? (
              specialCharacterLastName ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className={style.wrongIcon}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={style.checkIcon}
                />
              )
            ) : (
              <></>
            )}
          </span>
        </div>
      </div>
      <div className={style.labelLeft}>
        <label className={style.label}>
          Email <sup className={style.required}>*</sup>
        </label>
        <input
          placeholder="johnsmith@mail.com"
          className={style.inputFieldEmail}
          type="email"
          value={email}
          onChange={(e) => onChangeEmail(e.target.value)}
        />
        <span className={style.iconEmail}>
          {email !== "" ? (
            specialCharacterEmail ? (
              <FontAwesomeIcon
                icon={faTimesCircle}
                className={style.wrongIcon}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={style.checkIcon}
              />
            )
          ) : (
            <></>
          )}
        </span>
      </div>
      <label className={style.containerRadio}>
        By checking this box I confirm that I am over 18 years of age
        <input
          type="checkbox"
          name="radio"
          onChange={() => onChangeOverEighteen()}
        />
        <span className={style.checkmark}></span>
      </label>
      <div className={style.updateText}>
        Please update me on features, events, exclusive offers and competitions
        from
      </div>
      <label className={`${style.containerRadio} ${style.smallText}`}>
        Restaurant Association of Ireland
        <input
          type="checkbox"
          name="radio"
          onChange={(e) => onChangeSubscription(e.target.value)}
          id="Restaurant Association of Ireland"
          value="RAI"
        />
        <span className={style.checkmark}></span>
      </label>
      <label className={`${style.containerRadio} ${style.smallText}`}>
        The Irish Times
        <input
          type="checkbox"
          name="radio"
          onChange={(e) => onChangeSubscription(e.target.value)}
          id="The Irish Times"
          value="IT"
        />
        <span className={style.checkmark}></span>
      </label>
      <label className={`${style.containerRadio} ${style.smallText}`}>
        Morning News Digest
        <input
          type="checkbox"
          name="radio"
          onChange={(e) => onChangeSubscription(e.target.value)}
          id="Morning News Digest"
          value="MND"
        />
        <span className={style.checkmark}></span>
      </label>
      <Recaptcha response={getResponse} />
      <button
        className={notARobot ? style.submitBtn : style.submitBtnDisable}
        onClick={() => submitBtn()}
      >
        Submit Nomination{" "}
        <FontAwesomeIcon icon={faArrowRight} className={style.arrowRight} />
      </button>
      <div className={style.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Form;
