import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";

import Dashboard from "../views/Dashboard/Dashboard";

const RequireAuth = () => {
  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider.2g50et56719gr13ehtd5sdvj32.b3877fe9-7c1c-44a6-a02b-0b2ab7b2f1cb.accessToken"
  );
  let location = useLocation();

  // if (!accessToken) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }
  return <Dashboard />;
};

export default RequireAuth;
