import style from "./VotingConfirm.module.scss";
import spacing from "../../assets/scss/_spacing.module.scss";
import RightMenu from "../RightMenu/RightMenu";
import { VscArrowLeft } from "react-icons/vsc";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";

const VotingConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [votedCategory, setVotedCategory] = useState([]);
  const categories = [
    {
      label: "Best Restaurant – Sponsored by Tindal Wine Merchants",
      value: "Best Restaurant",
    },
    {
      label: "Best Chef – Sponsored by BWG Foodservice",
      value: "Best Chef",
    },
    {
      label: "Best Gastro Pub – Sponsored by Elavon",
      value: "Best Gastro Pub",
    },
    {
      label: "Best Wine Experience – Sponsored by Gilbeys with Bibendum",
      value: "Best Wine Experience",
    },
    {
      label: "Best Hotel & Guesthouse Restaurant – Sponsored by Frylite",
      value: "Best Hotel & Guesthouse Restaurant",
    },
    {
      label: "Best Restaurant Manager – Sponsored by AIB MS",
      value: "Best Restaurant Manager",
    },
    {
      label: "Best Casual Dining – Sponsored by Just Eat",
      value: "Best Casual Dining",
    },
    {
      label: "Best Newcomer – Sponsored by Monin",
      value: "Best Newcomer",
    },
    {
      label: "Best Customer Service – Sponsored by Dolmen Insurance Brokers",
      value: "Best Customer Service",
    },
    {
      label: "Pub of the Year – Sponsored by Jameson",
      value: "Pub of the Year",
    },
    {
      label: "Best Emerging Irish Cuisine",
      value: "Best Emerging Irish Cuisine",
    },
    {
      label:
        "Best World Cuisine – Sponsored by Stafford Lynch – San Pellegrino",
      value: "Best World Cuisine",
    },
    {
      label: "Best Café – Sponsored by Illy",
      value: "Best Café",
    },
    {
      label: "Best ‘Free From’ – Sponsored by Peroni Libera",
      value: "Best ‘Free From’",
    },
    {
      label: "Local Food Hero – Sponsored by The Irish Times",
      value: "Local Food Hero",
    },
    {
      label: "Best Cocktail Experience – Sponsored by Coca Cola HBC",
      value: "Best Cocktail Experience",
    },
    {
      label:
        "Best Private Dining & Club Restaurant – Sponsored by Wild Irish Game",
      value: "Best Private Dining & Club Restaurant",
    },
    {
      label: "Best Cookery School – Sponsored by Avonmore",
      value: "Best Cookery School",
    },
    {
      label: "Best Seafood Experience – Sponsored by Musgrave",
      value: "Best Seafood Experience",
    },
    {
      label: "Best Digital Marketing",
      value: "Best Digital Marketing",
    },
  ];
  let results = categories.filter((object1) => {
    return !votedCategory.some((object2) => {
      return object1.value === object2;
    });
  });
  const onGoToNominationPage = (value) => {
    navigate("/", {
      state: {
        selectedCategory: value,
      },
    });
  };
  const getAllCacheData = async () => {
    setVotedCategory([]);
    var url = "irish-times";
    // List of all caches present in browser
    var names = await caches.keys();
    let userInfo = names.filter((name) => name === "UserInfo");
    userInfo.forEach(async (name) => {
      // Opening that particular cache
      const cacheStorage = await caches.open(name);
      // Fetching that particular cache data
      const cachedResponse = await cacheStorage.match(url);
      var data = await cachedResponse.json();
      if (data.votedCategories.length > 0)
        data.votedCategories
          // eslint-disable-next-line array-callback-return
          .map((elem) => {
            setVotedCategory((prev) => [...prev, elem]);
          });
    });
  };
  useEffect(() => {
    getAllCacheData();
  }, []);
  const onGoBackToNominationPage = () => {
    navigate("/");
  };
  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, [location, navigate]);

  return (
    <div className={style.test}>
      {location.state ? (
        <div className={style.container}>
          {/* <div className={style.firstElement}>
            <RightMenu />
          </div> */}
          <div className={style.firstElement}>
            {/* <div className={`${style["text"]} ${spacing["mt-51"]}`}>
              Hi {location.state.firstName} {location.state.lastName},
            </div>
            <div className={style.text}>
              thank you for submitting your nomination for
            </div> */}

            <div className={`${style["restaurantName"]} ${spacing["mt-51"]}`}>
              {/* {location.state.restaurant} */}
              Thanks for your nomination!
            </div>
            <div className={`${style["categoryCounty"]} ${spacing["mt-10"]}`}>
              {/* Category:
              <span className={`${style["nomination"]}`}>
                {location.state.category}
              </span> */}
              Share your nomination and help them to win!
            </div>
            <div className={`${style["nominationText"]} ${spacing["mt-10"]}`}>
              {/* County:{" "}
              <span className={`${style["nomination"]}`}>
                {location.state.county}
              </span> */}
              <FontAwesomeIcon icon={faQuoteLeft} className={style.quoteLeft} />{" "}
              I have just nominated {location.state.restaurant} for{" "}
              {location.state.category} in the Irish Restaurant Awards 2022
              @restawards #FoodOscars{" "}
              <FontAwesomeIcon
                icon={faQuoteRight}
                className={style.quoteRight}
              />
            </div>
            <div className={style.shareButtons}>
              <br />
              <TwitterShareButton
                title={`I have just nominated ${location.state.restaurant} for ${location.state.category} in the Irish Restaurant Awards 2022`}
                url={"https://test.irishrestaurantawards-expleo.com/"}
                hashtags={["restawards", "FoodOscars"]}
              >
                <TwitterIcon size={32} round className={style.twitter} />
              </TwitterShareButton>
              <WhatsappShareButton
                title={`I have just nominated ${location.state.restaurant} for ${location.state.category} in the Irish Restaurant Awards 2022`}
                url={"https://test.irishrestaurantawards-expleo.com/"}
                hashtags={["restawards", "FoodOscars"]}
              >
                <WhatsappIcon size={32} round className={style.twitter} />
              </WhatsappShareButton>
              <FacebookShareButton
                url={"https://test.irishrestaurantawards-expleo.com/"}
                hashtag={["#restawards"]}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>
            {/* <div className={`${style["text"]} ${spacing["mt-51"]}`}>
              You will recieve an email of your nomination as a reciept, however
              if you change your mind you will be able to submit a new
              nomination
            </div> */}
            <div className={`${style["text"]} ${spacing["mt-51"]}`}>
              You can only nominate once per category, but why not show your
              support and nominate the same (or another) establishment in a
              different category?
            </div>
            <div className={`${style["title"]} ${spacing["mt-51"]}`}>
              Choose from the list below to cast a vote in a different category
            </div>
            {/* <div className={`${style["text"]} ${spacing["mt-51"]}`}>
              Regards
            </div>
            <div className={`${style["text"]} ${style["textPurple"]}`}>
              Irish Restaurants Awards Team
            </div> */}
            <div className={style.listOfCategories}>
              {results.map((elem, key) => (
                <div
                  key={key}
                  className={style.list}
                  onClick={() => {
                    onGoToNominationPage(elem.value);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    className={style.checkIcon}
                  />
                  {elem.label}
                </div>
              ))}
            </div>
            <div
              className={`${style["backButton"]}`}
              onClick={onGoBackToNominationPage}
            >
              <VscArrowLeft
                style={{
                  marginRight: "10px",
                  paddingBottom: "3px",
                }}
                size="20"
              ></VscArrowLeft>
              Back to nomination page
            </div>
          </div>
          <div className={style.footer}>
            <Footer />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default VotingConfirm;
