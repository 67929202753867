import React, { useState } from "react";
import style from "./LoginPage.module.scss";
import RightMenu from "../RightMenu/RightMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer/Footer";
import { Amplify, Auth, API } from "aws-amplify";
import awsconfig from "../../aws-exports.js";
import { useNavigate } from "react-router-dom";
Amplify.configure(awsconfig);
API.configure(awsconfig);

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const signIn = async () => {
    try {
      const signedInUser = await Auth.signIn(username, password);
      console.log(signedInUser);
      navigate("/dashboard");
    } catch (err) {
      toast.error("Invalid email or password !", {
        position: "top-right",
        progress: undefined,
      });
      return err;
    }
  };

  return (
    <div className={style.container}>
      {/* <div className={style.firstElement}>
        <RightMenu />
      </div> */}

      <div className={style.secondElement}>
        <div className={style.loginContainer}>
          <div className={style.titleLoginContainer}>
            Irish Restaurants Awards
          </div>
          <div className={style.subTitleLoginContainer}>
            Sign in to your account below
          </div>
          <div className={style.inputContainer}>
            <div className={style.label}>Email: </div>
            <input
              placeholder="Enter your email"
              type="email"
              className={style.inputField}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <div className={style.label}>Password: </div>
            <input
              placeholder="Enter your password"
              type="password"
              className={style.inputField}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className={style.forgotPassword}>Forgot password?</div>
          <button className={style.signInButton} onClick={signIn}>
            Sign In{" "}
            <FontAwesomeIcon icon={faArrowRight} className={style.arrowRight} />
          </button>
          <div className={style.requestAccess}>
            Don't have an account? <b>&nbsp;Please request access</b>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;
