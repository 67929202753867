import { createContext, useContext, useEffect, useRef, useState } from "react";
import { loadScript } from '../../utils/loadScript';

const GoogleMapsContext = createContext(null);

const useGoogleMaps = () => useContext(GoogleMapsContext);

const GoogleMapsProvider = ({ apiKey, children }) => {
  const [loading, setLoading] = useState(true);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const loaded = useRef(false);

  useEffect(() => {
    if (apiKey && !loaded.current && !document.querySelector('google-maps-script')) {
      const mapContainer = document.createElement('div')
      mapContainer.setAttribute('id', 'google-maps-container');
      document.body.append(mapContainer);

      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps-script'
      ).then(() => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        setAutocompleteService(autocompleteService);

        const map = new window.google.maps.Map(document.getElementById('google-maps-container'));
        const placesService = new window.google.maps.places.PlacesService(map);
        setPlacesService(placesService);

        setLoading(false);
      }).catch((error) => console.error(error));

      loaded.current = true;
    }
  }, [apiKey]);

  const getPlaceDetails = (placeId) => {
    return new Promise((resolve, reject) => {
      if (!placesService) {
        reject(new Error('Places Service not initialized'));
      }

      const request = {
        placeId,
        fields: ['name', 'address_components', 'formatted_address']
      };

      placesService.getDetails(request, (place, status) => {
        if (status === 'OK' && place) {
          resolve(place);
        } else {
          reject(new Error('Place could not be found'));
        }
      });
    });
  };

  const getPredictions = (input) => {
    return new Promise((resolve, reject) => {
      if (!autocompleteService) {
        reject(new Error('Autocomplete Service not initialized'));
      }

      const request = {
        input,
        // types: ['bar', 'cafe', 'restaurant', 'meal_delivery', 'meal_takeaway'],
        // types: ['bar', 'cafe', 'restaurant', 'food'],
        types: ['bar', 'cafe', 'restaurant'],
        componentRestrictions: {
          country: ['ie', 'gb'],
        },
        bounds: new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(50.999929, -10.854492),
          new window.google.maps.LatLng(55.354135, -5.339355)
        )
      };

      autocompleteService.getPlacePredictions(request, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else if (status === 'ZERO_RESULTS') {
          resolve([]);
        } else {
          reject(new Error(status));
        }
      });
    });
  };

  const value = {
    getPredictions,
    getPlaceDetails,
  };

  return (
    <GoogleMapsContext.Provider value={value}>
      {loaded.current && !loading ? children : null}
    </GoogleMapsContext.Provider>
  )
};

export { useGoogleMaps, GoogleMapsProvider };
