import React, { useEffect, useState } from "react";
import style from "./Voting.module.scss";
import RightMenu from "../RightMenu/RightMenu";
import Form from "./Form/Form";
import { useNavigate, useLocation } from "react-router";
import { useGoogleMaps } from "../../components/GoogleMaps/GoogleMapsProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataProvider } from "../../DataProvider";
import axios from "axios";

const Voting = () => {
  // used this useEffect to clear the user data on refresh.
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);
  // ---------INFO ABOUT RESTAURANT/CATEGORY/COUNTY/CITY
  const location = useLocation();
  const [chosenCategory, setChosenCategory] = useState(
    location.state ? location.state.selectedCategory : ""
  );
  const onChangeCategory = (e) => {
    setChosenCategory(e.target.value);
  };
  //------------------------------------------

  // --------------------PERSONAL INFO------

  let format = /[ `!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?~]/;
  let formatCategory = /[`!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]/;
  const validateEmail = (email) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };
  const [categoryId, setCategoryId] = useState(0);
  const [specialCharacterFirstName, setSpecialCharacterFirstName] =
    useState(false);
  const [specialCharacterLastName, setSpecialCharacterLastName] =
    useState(false);
  const [specialCharacterEmail, setSpecialCharacterEmail] = useState(false);
  const [specialCharacterCategoryName, setSpecialCharacterCategoryName] =
    useState(false);
  const [
    specialCharacterdescriptionValue,
    setSpecialCharacterdescriptionValue,
  ] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [place, setPlace] = useState(null);
  const [country, setCountry] = useState("");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [counties, setCounties] = useState([]);
  const [eircode, setEirCode] = useState("");

  useEffect(() => {
    let allCounties = [];
    const testURLCounties =
      process.env.REACT_APP_DASHBOARD_URL + "?view=county";
    axios.get(testURLCounties).then((response) => {
      response.data.map((elem) => {
        let container = {
          countyName: elem.county_name,
          countyId: elem.county_id,
        };
        allCounties.push(container);
      });
      setCounties(allCounties);
    });
  }, []);
  const { getPlaceDetails } = useGoogleMaps();

  useEffect(() => {
    if (place) {
      if (place.placeId) {
        getPlaceDetails(place.placeId).then(
          ({ address_components, formatted_address }) => {
            if (formatted_address) {
              place.description = formatted_address;
            }
            if (address_components) {
              const country = address_components.find((item) =>
                item.types.includes("country")
              )?.short_name;
              if (["IE", "GB"].includes(country)) {
                setCountry(country);
              } else {
                setCountry("");
              }

              const eircode = address_components.find((item) =>
                item.types.includes("postal_code")
              );
              if (eircode?.long_name || eircode?.short_name) {
                setEirCode(eircode?.long_name || eircode?.short_name);
              } else {
                setEirCode("");
              }

              const countyField =
                country === "IE"
                  ? "administrative_area_level_1"
                  : "administrative_area_level_2";
              const county = address_components.find((item) =>
                item.types.includes(countyField)
              );
              console.log("County", county);
              if (county?.long_name) {
                if (country === "IE") {
                  setCounty(county.long_name.replace(/County/i, "").trim());
                } else {
                  setCounty(county.long_name);
                }
              } else {
                setCounty("");
              }

              // const cityField = country === "IE" ? "locality" : "postal_town";
              const city = address_components.find(
                (item) =>
                  item.types.includes("locality") ||
                  item.types.includes("postal_town")
              );
              console.log("City", city);
              if (city?.long_name || city?.short_name) {
                setCity(city?.long_name || city?.short_name);
              } else {
                setCity("");
              }
            }
          }
        );
      } else {
        setCountry("not_listed");
        setCounty("");
        setCity("");
      }
    } else {
      setCountry("");
      setCounty("");
      setCity("");
    }
  }, [place, getPlaceDetails]);

  const [overEighteen, setOverEighteen] = useState(false);
  const [subscription, setSubscription] = useState([]);

  const onChangeSubscription = (e) => {
    setSubscription((prevSelected) => {
      // if it's in, remove
      const newArray = [...prevSelected];
      if (newArray.includes(e)) {
        return newArray.filter((item) => item !== e);
        // else, add
      } else {
        newArray.push(e);
        return newArray;
      }
    });
  };
  const onChangeOverEighteen = () => {
    setOverEighteen(!overEighteen);
  };
  const onChangeFirstName = (e) => {
    setFirstName(e);
    if (!format.test(e)) {
      setSpecialCharacterFirstName(false);
    } else {
      setSpecialCharacterFirstName(true);
    }
  };
  const onChangeLastName = (e) => {
    setLastName(e);
    if (!format.test(e)) {
      setSpecialCharacterLastName(false);
    } else {
      setSpecialCharacterLastName(true);
    }
  };
  const onChangeCategoryName = (e) => {
    setCategoryName(e);
    if (!formatCategory.test(e)) {
      setSpecialCharacterCategoryName(false);
    } else {
      setSpecialCharacterCategoryName(true);
    }
  };

  const onChangeCategoryDescription = (e) => {
    setDescriptionValue(e);
    if (!formatCategory.test(e)) {
      setSpecialCharacterdescriptionValue(false);
    } else {
      setSpecialCharacterdescriptionValue(true);
    }
  };
  const onChangeEmail = (e) => {
    setEmail(e);
    if (validateEmail(e)) {
      setSpecialCharacterEmail(false);
    } else {
      setSpecialCharacterEmail(true);
    }
  };
  // ---------------------------/PERSONAL INFO--------------------------
  const getCountyId = (countyName) => {
    let countyId;
    counties.map((elem) => {
      if (elem.countyName.toString().toLowerCase() === countyName.toString().toLowerCase()) {
        countyId = elem.countyId;
      }
    });
    return countyId;
  };
  //-----------------SUBMIT BUTTON---------------
  let navigate = useNavigate();
  const submitBtn = () => {
    if (!overEighteen) {
      toast.error("Sorry, you must be over 18 to vote", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (place === null) {
      toast.error("Please enter the name of hospitality business", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (country === "") {
      toast.error("Please select the restaurant location", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (chosenCategory === "") {
      toast.error("Please select a nomination category", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (county === "" || !getCountyId(county)) {
      toast.error("Please select a business county", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (city === "") {
      toast.error("Please select a business city / town / village", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (email === "" || firstName === "" || lastName === "") {
      toast.error("Please complete your personal details", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (format.test(firstName)) {
      toast.error("Please enter a valid first name", {
        position: "top-right",
        progress: undefined,
      });
    } else if (format.test(lastName)) {
      toast.error("Please enter a valid surname", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (!validateEmail(email)) {
      toast.error("Please enter a valid email", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (
      (chosenCategory === "Best Chef" ||
        chosenCategory === "Best Restaurant Manager" ||
        chosenCategory === "Best Hotel & Guesthouse Restaurant" ||
        chosenCategory === "Local Food Hero") &&
      (categoryName === "" || !categoryName)
    ) {
      toast.error("Please add " + chosenCategory + " Name", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (
      (chosenCategory === "Best Chef" ||
        chosenCategory === "Best Restaurant Manager" ||
        chosenCategory === "Best Hotel & Guesthouse Restaurant" ||
        chosenCategory === "Local Food Hero") &&
      formatCategory.test(categoryName)
    ) {
      toast.error("Please enter a valid " + chosenCategory + " Name", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (
      chosenCategory === "Local Food Hero" &&
      (descriptionValue === "" || !descriptionValue)
    ) {
      toast.error("Please add " + chosenCategory + " Description", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else if (
      chosenCategory === "Local Food Hero" &&
      formatCategory.test(descriptionValue)
    ) {
      toast.error("Please enter a valid " + chosenCategory + " Description", {
        position: "top-right",
        progress: undefined,
      });
      return;
    } else {
      const data = {
        place_id: place.placeId ? place.placeId : "unknown",
        address: place.description,
        restaurant: place.name,
        restaurant_location: country,
        category: chosenCategory,
        category_id: categoryId,
        county: county,
        county_id: getCountyId(county),
        region_id: country === "IE" ? "1" : country === "GB" ? "2" : "0",
        city: city,
        user_firstName: firstName,
        user_surName: lastName,
        email: email,
        overEighteen: overEighteen,
        subscription: subscription,
        eircode: eircode,
      };

      // if (chosenCategory === "Best Chef") {
      //   let container = {
      //     attribute_name: "Best Chef Name",
      //     value: categoryName,
      //   };
      //   data["category_additional_attributes"] = [container];
      // } else if (chosenCategory === "Best Restaurant Manager") {
      //   let container = {
      //     attribute_name: "Best Restaurant Manager Name",
      //     value: categoryName,
      //   };
      //   data["category_additional_attributes"] = [container];
      // } else if (chosenCategory === "Best Hotel & Guesthouse Restaurant") {
      //   let container = {
      //     attribute_name: "Best Hotel & Guesthouse Restaurant Name",
      //     value: categoryName,
      //   };
      //   data["category_additional_attributes"] = [container];
      // } else if (chosenCategory === "Local Food Hero") {
      //   let container = {
      //     attribute_name: "Best Hero Name",
      //     value: categoryName,
      //   };
      //   let container2 = {
      //     attribute_name: "Best Hero Description",
      //     value: descriptionValue,
      //   };
      if (chosenCategory === "Best Chef") {
        let container = {
          "best_chef_name" : categoryName, 
        };
        data["category_additional_attributes"] = container;
      } else if (chosenCategory === "Best Restaurant Manager") {
        let container = {
          "best_restaurant_manager_name" : categoryName,
        };
        data["category_additional_attributes"] = container;
      } else if (chosenCategory === "Best Hotel & Guesthouse Restaurant") {
        let container = {
          "best_hotel_guesthouse_restaurant_name" : categoryName,
        };
        data["category_additional_attributes"] = container;
      } else if (chosenCategory === "Local Food Hero") {
        let container = {
          "best_hero_name" : categoryName,
          "best_hero_description" : descriptionValue,
        };
        // let container2 = {
        //   "Best Hero Description" : descriptionValue,
        // };
        data["category_additional_attributes"] = container;
        // data["hero_description"] = descriptionValue;
      }
      test(data);
    }
  };
  const [votedCategory, setVotedCategory] = useState([]);
  const getAllCacheData = async () => {
    setVotedCategory([]);
    var url = "irish-times";
    // List of all caches present in browser
    var names = await caches.keys();
    let userInfo = names.filter((name) => name === "UserInfo");
    if (userInfo.length !== 0) {
      userInfo.forEach(async (name) => {
        // Opening that particular cache
        const cacheStorage = await caches.open(name);
        // Fetching that particular cache data
        const cachedResponse = await cacheStorage.match(url);
        var data = await cachedResponse.json();
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        if (data.votedCategories.length > 0)
          data.votedCategories
            .filter((elem) => elem !== "")
            // eslint-disable-next-line array-callback-return
            .map((elem) => {
              setVotedCategory((prev) => [...prev, elem]);
            });
      });
    } else {
      setVotedCategory([]);
    }
  };
  useEffect(() => {
    getAllCacheData();
  }, []);

  useEffect(() => {
    setVotedCategory((prev) => [
      ...prev.filter((elem) => elem !== ""),
      chosenCategory,
    ]);
  }, [chosenCategory]);
  //-------------/SUBMIT BUTTON
  const test = (data) => {
    console.log(data);
    setIsLoading(true);
    const testURL =
      // "https://33pmnf9gej.execute-api.eu-west-1.amazonaws.com/dev";
      // "https://33pmnf9gej.execute-api.eu-west-1.amazonaws.com/dev/rai-vote";
      // "https://1981tnj9f2.execute-api.eu-west-1.amazonaws.com/dev/dev-rai-vote-submission";
      process.env.REACT_APP_SUBMISSION_URL;

    let isMobile = false;
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      isMobile = true;
    }

    data.source = isMobile ? "mobile" : "desktop";
    const myInit = {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify(data),
    };

    const myRequest = new Request(testURL, myInit);

    fetch(myRequest)
      .then(function (response) {
        return response;
      })
      .then(function () {
        navigate("/confirmation", {
          state: {
            lastName: lastName,
            firstName: firstName,
            email: email,
            restaurant: place.name,
            category: chosenCategory,
            county: county,
          },
        });
        //store in browser cache user info + voted categories
        addDataIntoCache("UserInfo", "irish-times", {
          lastName: lastName,
          firstName: firstName,
          email: email,
          votedCategories: votedCategory,
        });
      })
      .catch(function (e) {
        console.log(e);
        setIsLoading(false);
      });
  };

  const addDataIntoCache = (cacheName, url, response) => {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(response));
    if ("caches" in window) {
      // Opening given cache and putting our data into it
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  };
  //-------------------------------------------------------------------

  return (
    <div className={style.container}>
      {/* <div className={style.firstElement}>
        <RightMenu />
      </div> */}

      <div className={style.secondElement}>
        {isLoading ? (
          <div className={style.overlay}>
            <div className={style.modal_backdrop}>
              <div className={style.loader}></div>
            </div>
          </div>
        ) : null}
        <DataProvider>
          <Form
            setCategoryId={setCategoryId}
            specialCharacterFirstName={specialCharacterFirstName}
            specialCharacterLastName={specialCharacterLastName}
            specialCharacterEmail={specialCharacterEmail}
            specialCharacterCategoryName={specialCharacterCategoryName}
            specialCharacterdescriptionValue={specialCharacterdescriptionValue}
            firstName={firstName}
            lastName={lastName}
            email={email}
            place={place}
            categoryName={categoryName}
            descriptionValue={descriptionValue}
            onChangePlace={setPlace}
            country={country}
            onChangeCountry={setCountry}
            county={county}
            onChangeCounty={setCounty}
            city={city}
            onChangeCity={setCity}
            onChangeFirstName={onChangeFirstName}
            onChangeLastName={onChangeLastName}
            onChangeEmail={onChangeEmail}
            onChangeCategoryName={onChangeCategoryName}
            submitBtn={submitBtn}
            chosenCategory={chosenCategory}
            onChangeCategory={onChangeCategory}
            onChangeOverEighteen={onChangeOverEighteen}
            onChangeSubscription={onChangeSubscription}
            onChangeCategoryDescription={onChangeCategoryDescription}
          />
        </DataProvider>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Voting;
