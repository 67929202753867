import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Voting from "../views/VotingPage/Voting";
import VotingConfirm from "../views/ConfirmVotingPage/VotingConfirm";
import LoginPage from "../views/LoginPage/LoginPage";
import RequireAuth from "../utils/requireAuth";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<LoginPage />}></Route>
        <Route exact path="/" element={<Voting />}></Route>
        <Route exact path="/confirmation" element={<VotingConfirm />}></Route>
        <Route exact path="/dashboard" element={<RequireAuth />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
