export function loadScript(src, position, id) {
  return new Promise((resolve, reject) => {
    if (!position) {
      reject(new Error('Script position not provided.'));
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    script.addEventListener('load', resolve, false);

    position.appendChild(script);
  });
}